.lede {
  @include esp-font-size(24px, 100%);

  color: $esp-brand-secondary;
  font-weight: bold;
  letter-spacing: -0.05em;
  margin-bottom: 1em;
  text-transform: uppercase;

  @include esp-media-query('lap-and-up') {
    @include esp-font-size(30px, 100%);
  }

  @include esp-media-query('desk') {
    @include esp-font-size(40px, 100%);
  }
}
