.logos {
  background-color: #F3F3F3;
  margin-bottom: 2rem;
  padding: 2rem 1rem;

  .page-inner {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @include esp-media-query('palm-horiz-and-up') {
      flex-wrap: nowrap;
    }
  }
    .logo__title {
      display: none;

      @include esp-media-query('lap-and-up') {
        @include esp-font-size(20px, 100%);

        align-items: center;
        border-right: 1px solid #1A262E;
        color: #1A262E;
        display: flex;
        flex: 0 auto;
        letter-spacing: -0.05em;
        padding-right: 1rem;
        margin: 0 1rem 0 0;
        max-width: 23%;
        text-transform: uppercase;
      }

      @include esp-media-query('portable') {
        @include esp-font-size(26px, 100%);
      }

      @include esp-media-query('desk') {
        @include esp-font-size(30px, 100%);
      }
    }

    .logo {
      flex: auto;
      padding-bottom: 1rem;
      padding-left: 0.5rem;
      text-align: center;

      @include esp-media-query('palm-horiz-and-up') {
        padding-bottom: 0;
      }

      @include esp-media-query('lap-and-up') {
        padding-left: 2rem;
      }
    }
}
