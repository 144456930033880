/**
 * High-, page-level styling.
 *
 * 1. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 * 3. Prevent certain mobile browsers from automatically zooming fonts.
 * 4. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 * 5. Responsive font size set in rem. Unit-less line-height (Sassline)
 */
html {
  background-color: $esp-base-background-color;
  color: $esp-base-text-color;
  -moz-osx-font-smoothing: grayscale; /* [4] */
   -webkit-font-smoothing: antialiased; /* [4] */
  overflow-y: scroll; /* [1] */
  min-height: 100%; /* [2] */
  text-size-adjust: 100%; /* [3] */
}

body {
  background-color: $esp-base-body-color;
  font-family: $esp-base-font-family;
  line-height: 1.5rem;
}

a {
  color: $esp-base-link-color;
  border-bottom: 1px dotted $esp-base-link-color;
  text-decoration: none;

  &:visited {
    color: $esp-base-link-color-visited;
    border-bottom-color: $esp-base-link-color-visited;
  }

  &.is-active,
  &:active,
  &:hover {
    color: $esp-base-link-color-hover;
    border-bottom-color: $esp-base-link-color-hover;
    border-bottom-style: solid;
  }
}

address {
  font-style: normal;
}
