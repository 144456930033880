.navigation--ftr {
  text-align: left;

  .navigation {
    &__list {
      margin-bottom: 0;
    }

    &__item {
      font-style: italic;

      @include esp-media-query('palm-horiz-and-up') {
        display: inline-block;

        &:not(:last-child) {
          padding-right: 1em;
        }
      }
    }

      &__link {
        border-bottom: 0;
      }
  }
}
