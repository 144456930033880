.page-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1175px;
  width: 100%;

  &.page-inner--narrow {
    max-width: 897px;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 4rem;

    h3 {
      color: $esp-brand-secondary;
      font-size: 2.5em;
      font-weight: bold;
      letter-spacing: -0.05em;
      line-height: 1em;
      text-transform: uppercase;

      &:not(:first-child) {
        margin-top: 3rem;
      }
    }
  }
}
