.btn {
  border: 1px solid $esp-base-link-color;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  padding-bottom: $esp-base-spacing-unit--small;
  padding-left: $esp-base-spacing-unit;
  padding-right: $esp-base-spacing-unit;
  padding-top: $esp-base-spacing-unit--small;
  text-transform: uppercase;

  &:hover {
    background-color: $esp-base-link-color;
    color: $esp-base-btn-color-hover;
  }
}
