.navigation--hdr {
  background: $esp-brand-primary;
  bottom: 0;
  display: none;
  left: 0;
  padding-right: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  @include esp-media-query('desk') {
    padding-right: 0;
    position: static;
  }

  &.is-active {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: scroll;

    @include esp-media-query('palm-horiz') {
      justify-content: flex-start;
    }
  }

  @include esp-media-query('desk') {
    background: none;
    display: block;
    float: right;
    padding-right: 1rem;
  }

  @include esp-media-query('desk-wide') {
    padding-right: 0;
  }

  .navigation {
    &__list {
      @include esp-media-query('desk') {
        display: flex;
        height: 100%;
        margin: 0;

        :first-child {
          padding-left: 0;
        }

        :last-child {
          padding-right: 0;
        }
      }
    }

    &__item {
      padding: 1rem 0;

      @include esp-media-query('desk') {
        flex: 0 auto;
        padding: 0 1.5rem;

        &:hover,
        &.is-active {
          .navigation__link {
            border-top-color: $esp-brand-primary;
            color: $esp-brand-primary;
          }
        }
      }
    }

    &__link {
      @include esp-font-size(20px);

      border: 0;
      color: #fff;
      font-weight: bold;
      padding: 0;
      text-transform: uppercase;

      @include esp-media-query('desk') {
        @include esp-font-size(13px);

        border-top: 2px solid transparent;
        display: inline-block;
        padding: 3rem 0;
      }
    }

    &__close {
      border: 0;
      color: #fff;
      font-weight: bold;
      position: absolute;
      right: 1rem;
      top: 1rem;

      @include esp-media-query('desk') {
        display: none;
      }
    }
  }
}

@include esp-media-query('desk') {
  .js {
    .navigation--hdr {
      position: relative;

      .navigation__slider {
        display: block;
        border-top: 2px solid $esp-brand-primary;
        position: absolute;
        left: 0;
        top: 0;

        &.is-initialised {
          transition: width 0.3s ease,
                      left 0.3s ease;
        }
      }

      .navigation__link {
        border-top-color: transparent !important;
      }
    }
  }
}
