/**
 * Remove extra vertical spacing when nesting lists.
 */
li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

ol,
ul {
  margin-left: 1.75rem;

  &.is-bare {
    list-style-type: none;
    margin-left: 0;
  }

  li {
    padding-bottom: 0.25rem;
  }
}
