.wireframe {
  background-color: #EDEEF0;

  &__image {
    @extend .page-inner;

    @include esp-media-query('desk') {
      background-image: url(/images/cnc-machine.png);
      background-repeat: no-repeat;
      background-position: top right;

      padding-bottom: 4rem;
    }
  }

    &__inner {
      padding: 1rem;

      @include esp-media-query('desk') {
        margin: 0 auto;
        max-width: 897px;
      }
    }

      &__content {
        padding: 3rem 1rem;

        @include esp-media-query('desk') {
          max-width: 568px;
          padding: 6rem 4rem 6rem 0;
        }
      }

        &__title {
          color: $esp-brand-secondary;
          font-size: 2.5em;
          font-weight: bold;
          letter-spacing: -0.05em;
          line-height: 1em;
          text-transform: uppercase;

          strong {
            color: $esp-brand-primary;
          }
        }
}
