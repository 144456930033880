// Hidden from all view
.is-hidden {
  display: none;
}

// Viewable for screen-readers
.is-hidden-visually {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Allows screen readers to focus. Useful for 'skip navigation' links
  &.is-focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

// Invisible but will take up the usual space
.is-invisible {
  visibility: hidden;
}
