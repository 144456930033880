.ir {
  background-position: top left;
  background-repeat: no-repeat;
  border-width: 0;
  color: transparent;
  display: block;
  font-family: a;
  font-size: 0;
  line-height: 0;
  text-shadow: none;
}
