$esp-form-field-border-focus : #3596d7 !default;
$esp-form-field-disabled     : #eceeef !default;
$esp-form-field-error        : #cc0000 !default;

form {
  margin-bottom: $esp-base-spacing-unit;

  .btn {
    line-height: normal; // Firefox can do this anyway. Should give even button/input height
  }
}

fieldset {
  border: none;
}

.form-group {
  @extend %margin-bottom;
}

  .form-label {
    display: inline-block;
    margin-bottom: $esp-base-spacing-unit--tiny;
  }

  /**
   * 1. Reset unusual Firefox-on-Android default style;
   *    see https://github.com/necolas/normalize.css/issues/214.
   */
  .form-field {
    background-color: #fff;
    background-image: none; /* [1] */
    border-color: rgba(0,0,0,0.15);
    border-style: solid;
    border-width: 1px;
    border-radius: 0.25rem;
    box-shadow: none;
    display: block;
    line-height: normal;
    outline: 0;
    padding-bottom: $esp-base-spacing-unit--small;
    padding-left: $esp-base-spacing-unit;
    padding-right: $esp-base-spacing-unit;
    padding-top: $esp-base-spacing-unit--small;
    transition: border-color 0.15s ease-in-out;
    vertical-align: middle;
    width: 100%;

    // Unstyle the caret on `<select>`s in IE10+
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &:focus {
      border-color: rgba(0,0,0,0.30);
    }
  }

.form-group:disabled .form-field,
.form-field:disabled {
  background-color: $esp-form-field-disabled;
  cursor: not-allowed;
  opacity: 0.65;
}

.form-group.has-error .form-field {
  border-color: $esp-form-field-error;
}

.form-group.is-inline {
  .form-field {
    width: auto;
    display: inline-block;
  }
}
