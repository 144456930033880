.page {
  &--index {
    .block--overlay {
      position: relative;

      @include esp-media-query('desk') {
        &:after {
          background: url('../images/icons/scroll.png') no-repeat;
          bottom: 1rem;
          content: '';
          display: block;
          height: 57px;
          left: 50%;
          margin-left: -12px;
          position: absolute;
          width: 45px;
        }
      }
    }
  }

  &--development,
  &--production {
    .page-inner--narrow {
      padding-bottom: 5rem;
      padding-top: 7rem;

      h3:not(:first-child) {
        margin-top: 6rem;
      }
    }
  }
}
