.hero {
  background-color: $esp-brand-secondary;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &__inner {
    @extend .page-inner;

    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 4rem 0;

    @include esp-media-query('lap-and-up') {
      min-height: 570px;
    }
  }

    &__title {
      @include esp-font-size(30px, 100%);

      background-color: $esp-brand-primary;
      color: #fff;
      left: 0;
      margin: 0 0 0 -9rem;
      padding: 0.75em 1em 0.75em 10rem;
      text-transform: uppercase;

      @include esp-media-query('lap-and-up') {
        @include esp-font-size(50px, 100%);

        margin: 0 0 0 -98rem;
        padding: 1em 1em 1em 100rem;
      }
    }
}
