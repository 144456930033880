.carousel {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;

  @include esp-media-query('lap-and-up') {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @include esp-media-query('desk') {
    margin-left: 0;
    margin-right: 0;
  }

    &__nav {}
      .owl-prev,
      .owl-next {
        display: none;

        @include esp-media-query('lap-and-up') {
          @include esp-font-size(40px, 100%);

          background-color: rgba(0,0,0,0.5);
          border: 0;
          color: #fff !important;
          display: block;
          font-weight: 100;
          padding: 0 0.42em 0.1em;
          position: absolute;
          top: 40%;

          &:hover {
            background-color: rgba(255,255,255,0.5);
          }
        }

        @include esp-media-query('desk') {
          @include esp-font-size(60px, 100%);
        }
      }

      .owl-prev {
        left: 1em;
      }

      .owl-next {
        right: 1em;
      }

        .owl-page {
          span {
            background-color: #AFAFAF;
            transition: background-color 0.5s ease;
            width: 0.75em;

            &:hover {
              background-color: $esp-brand-primary;
            }
          }

          &.active {
            span {
              background-color: $esp-brand-primary;
            }
          }
        }
}
