.block {
  @include esp-media-query('desk') {
    align-items: stretch;
    display: flex;
    flex-direction: row-reverse;
  }

  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    min-height: 340px;

    @include esp-media-query('lap-and-up') {
      display: block;
    }

    @include esp-media-query('desk') {
      width: 50%;
    }
  }

  &__content {
    @extend .clearfix;

    padding: 4rem 1rem;

    @include esp-media-query('desk') {
      width: 50%;
    }

    .block__inner {

      @include esp-media-query('lap-and-up') {
        padding-bottom: 5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 5rem;
      }

      @include esp-media-query('desk') {
        float: right;
        max-width: 584px;
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }
  }

    &__title {
      color: $esp-brand-secondary;
      font-size: 2.5em;
      font-weight: bold;
      letter-spacing: -0.05em;
      line-height: 1em;
      text-transform: uppercase;

      strong {
        color: $esp-brand-primary;
      }
    }

    &__link {
      background-color: $esp-brand-primary;
      border: none;
      color: #FFFFFF !important;
      display: inline-block;
      font-weight: bold;
      margin-top: 1rem;
      padding: 1rem 1.5rem;
      text-transform: uppercase;

      &:hover {
        background-color: darken($esp-brand-primary, 10);
        border: none;
      }
    }

  &--primary,
  &--secondary {
    background-color: $esp-brand-primary;
    color: #fff;

    .block {
      &__title {
        color: #fff;
      }

      &__link {
        border-color: #fff;
        color: #fff;
      }
    }
  }

  &--grey {
    background-color: #EDEEF0;
  }

  &--secondary {
    background-color: $esp-brand-secondary;
  }

  &--reversed {
    flex-direction: row;

    .block__content {
      .block__inner {
        @include esp-media-query('lap-and-up') {
          float: left;
        }
      }
    }
  }

  &--stacked {
    display: block;

    .block__content {
      @extend .page-inner;

      padding-bottom: 2rem;
      padding-top: 2rem;

      .block__inner {
        float: none;
        padding-left: 0;
        padding-right: 0;
        @include esp-media-query('desk') {
          max-width: 75%;
        }
      }
    }

    .block__image {
      min-height: 800px;
      width: 100%;
    }
  }

  // &--collapse {
  //   .page-inner {
  //     flex-direction: column-reverse;

  //     @include esp-media-query('desk') {
  //       flex-direction: row;
  //     }
  //   }

  //   @include esp-media-query('desk') {
  //     &.block--reversed {
  //       .page-inner {
  //         flex-direction: row-reverse;
  //       }
  //     }
  //   }

  //   .block {
  //     &__image {
  //       @include esp-media-query('lap-and-up') {
  //         display: block;
  //         width: 100%;

  //         img {
  //           max-width: none;
  //         }
  //       }

  //       @include esp-media-query('desk') {
  //         width: 50%;
  //       }
  //     }

  //     &__content {
  //       @include esp-media-query('lap-and-up') {
  //         width: 100%;
  //       }

  //       @include esp-media-query('desk') {
  //         width: 50%;
  //       }
  //     }
  //   }
  // }

  // &--broad {
  //   .block__image {
  //     text-align: center;
  //   }

  //   @include esp-media-query('lap-and-up') {
  //     .page-inner,
  //     .block__image,
  //     .block__content {
  //       display: block;
  //       width: 100%;
  //     }

  //     .page-inner {
  //       padding-top: 0;
  //     }
  //   }

  //   @include esp-media-query('desk') {
  //     .page-inner {
  //       display: flex;
  //     }

  //     .block__image,
  //     .block__content {
  //       width: 50%;
  //     }

  //     .block__image {
  //       direction: rtl;
  //       text-align: right;
  //     }
  //   }
  // }

  &--overlay {
    align-items: center;
    background-color: $esp-brand-secondary;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    justify-content: center;
    padding: 1rem;
    text-align: center;

    @include esp-media-query('palm-horiz-and-up') {
      padding: 2rem;
    }

    @include esp-media-query('lap-and-up') {
      padding: 4rem 2rem;
    }

    @include esp-media-query('desk') {
      padding: 8rem 2rem;
    }

    .block__title {
      font-size: 2em;

      @include esp-media-query('lap-and-up') {
        font-size: 2.5em;
      }
    }

    .block__content {
      @extend .page-inner;

      p {
        @include esp-font-size(20px);

        @include esp-media-query('lap-and-up') {
          margin-left: auto;
          margin-right: auto;
          max-width: 70%;
        }
      }
    }

      .block__title {
        color: #fff;

        @include esp-media-query('lap-and-up') {
          @include esp-font-size(90px, 100%);
        }

        @include esp-media-query('desk-wide') {
          @include esp-font-size(100px, 100%);
        }
      }
  }
}

.block--narrow {
  display: block;

  .page-inner {
    @include esp-media-query('desk') {
      align-items: stretch;
      display: flex;
      flex-direction: row-reverse;
    }
  }

    .block__content {
      padding-left: 0;
    }

    .block__image {
      padding-right: 0;
    }
}
