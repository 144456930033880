// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include widths(3 4, -sm);

// Predefine the variables below in order to alter and enable specific features.
// Do we want to use classes like `<div class="u-1/4">` instead of
// `<div class="u-1-of-4">`?
$use-fractions: true !default;

// Depending on what we chose for `$use-fractions`, create the relevant
// delimiter.
@if ($use-fractions == true) {
    $widths-delimiter: \/ !global;
} @else {
    $widths-delimiter: -of- !global;
}

@mixin widths($widths-columns, $widths-breakpoint: null) {
    // Loop through the number of columns for each denominator of our fractions.
    @each $widths-denominator in $widths-columns {
        // If we’re trying to make wholes, just spit a 100% width utility out
        // one time only.
        @if ($widths-denominator == 1) {
            .u-1#{$widths-delimiter}1#{$widths-breakpoint} {
                width: 100% !important;
            }
        } @else {
            // Begin creating a numberator for our fraction up until we hit the
            // denominator.
            @for $widths-numerator from 1 to $widths-denominator {
                // Build a class in the format `.u-3/4` or `.u-3-of-4`.
                .u-#{$widths-numerator}#{$widths-delimiter}#{$widths-denominator}#{$widths-breakpoint} {
                    width: ($widths-numerator / $widths-denominator) * 100% !important;
                }
            }
        }
    }
}

$esp-widths-columns: (
    1,
    2,
    3,
    4,
    5,
) !default;

@include widths($esp-widths-columns);

/*------------------------------------*\
    #WIDTHS-RESPONSIVE
\*------------------------------------*/

/**
 * Responsive width classes based on your responsive settings.
 */

// By default we will create wholes, halves, thirds, quarters, and fifths.
// Predefine this Map to override.
$esp-widths-columns-responsive: (
    1,
    2,
    3,
    4,
    5,
) !default;

// Loop over our breakpoints defined in _settings.responsive.scss
@each $breakpoint in $esp-breakpoints {

  // Get the name of the breakpoint.
  $alias: nth($breakpoint, 1);

  @include esp-media-query($alias) {

    // Loop through each of our column sizes and generate its responsive width
    // classes.
    @each $widths-column in $esp-widths-columns-responsive {
      @include widths($widths-column, -#{$alias});
    }
  }
}
