html {
  box-sizing: border-box;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

/**
 * As well as using normalize.css, it is often advantageous to remove all
 * margins from certain elements.
 */
body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
  margin:  0;
  padding: 0;
}

/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */
abbr[title],
dfn[title] {
  cursor: help;
}

/**
 * Remove underlines from potentially troublesome elements.
 */
u,
ins {
  text-decoration: none;
}

/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */
ins {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,
fieldset, figure,
pre,
%margin-bottom {
  margin-bottom: $esp-base-spacing-unit;
  margin-bottom: ($esp-base-spacing-unit / 16px) * 1rem;
}

/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol,
%margin-left {
  margin-left: $esp-base-spacing-unit;
  margin-left: ($esp-base-spacing-unit / 16px) * 1rem;
}
