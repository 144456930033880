table {
  width: 100%;
}

  caption {
    text-align: left;

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }

    p {
      font-style: italic;
    }
  }

  th,
  td {
    border-bottom: 1px solid #000000;
    padding: $esp-base-spacing-unit--small;
    text-align: left;
  }

  td {
    // text-align: center;

    @include esp-media-query('palm-horiz-and-up') {
      text-align: left;
    }
  }
