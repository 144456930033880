.page-footer {
  background-color: #DFDFDD;

  &__inner {
    padding: 1rem 0 1rem 1rem;
  }

    .copyright {
      display: block;
      line-height: 1.2em;
      padding-bottom: 1rem;
    }

    .certifications {
      text-align: right;

      &__img {
        margin: 0 0.5rem;
      }
    }
}
