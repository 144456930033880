@mixin esp-font-size($font-size, $line-height: auto) {
    font-size: $font-size;
    font-size: ($font-size / 16px) * 1rem;

    @if $line-height == auto {
        line-height: ceil($font-size / $esp-base-line-height) * ($esp-base-line-height / $font-size);
    }
    @else {
        @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height;
        }
        @elseif ($line-height != none and $line-height != false) {
            @warn "‘#{$line-height}’ is not a valid value for `line-height`."
        }
    }
}

@mixin esp-media-query($mq) {
    $breakpoint-found: false;
    // Loop through the list of breakpoints we’ve provided in our settings file.
    @each $breakpoint in $esp-breakpoints {
        // Grab the alias and the condition from their respective locations in
        // the list.
        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // If the media query we’ve specified has an alias and a condition...
        @if $mq == $alias and $condition {
            // ...tell the mixin that we’ve found it...
            $breakpoint-found: true;

            // ...and spit it out here.
            @media #{$condition} {
                @content;
            }
        }
    }

    // If the user specifies a non-exitent alias, send them a warning.
    @if $breakpoint-found == false{
        @warn "Breakpoint ‘#{$mq}’ does not exist."
    }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}
