.page-hdr {
  @extend .clearfix;

  background-color: $esp-brand-secondary;
  position: relative;

  &__logo {
    background-image: url('../images/esp-logo.png');
    background-size: cover;
    float: left;
    height: 35px;
    margin: 1rem;
    width: 73px;

    @include esp-media-query('lap-and-up') {
      height: 59px;
      margin: 2rem;
      width: 120px;
    }
  }

  .hamburger {
    @include esp-font-size(35px, 100%);

    border: none !important;
    color: #fff !important;
    float: right;
    margin: 1rem;

    @include esp-media-query('lap-and-up') {
      @include esp-font-size(59px, 100%);

      margin: 2rem;
    }

    @include esp-media-query('desk') {
      display: none;
    }
  }
}
