.cards {
  margin: 4rem auto;
  max-width: 1024px;

  @include esp-media-query('portable') {
    display: flex;
    justify-content: center;
  }

  @include esp-media-query('desk') {
    display: flex;
    justify-content: center;
  }
}
  .card {
    text-align: center;

    @include esp-media-query('portable') {
      flex: 0 1 auto;
      width: 30%;
    }

    @include esp-media-query('desk') {
      flex: 0 1 auto;
      width: 30%;
    }

    &__image {
      align-items: center;
      background-color: $esp-brand-secondary;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      height: 142px;
      margin: 0 auto 2rem;
      width: 142px;
    }

    &__title {
      color: $esp-brand-secondary;
      font-size: 2em;
      font-weight: bold;
      letter-spacing: -0.05em;
      line-height: 1em;
      text-transform: uppercase;
    }

    a {
      border: 0;
      color: $esp-brand-primary;
      font-weight: bold;
    }
  }
