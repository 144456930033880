.navigation {
  text-align: center;

  &__list {
    list-style: none;
    margin-left: 0;
  }

    &__item {
      padding-bottom: $esp-base-spacing-unit;
    }

      &__link {}

  &.is-inline {
    .navigation {
      &__item {
        display: inline-block;
        margin-left: $esp-base-spacing-unit--small;
        margin-right: $esp-base-spacing-unit--small;
      }
    }
  }
}

.menu-trigger {
  cursor: pointer;
}
