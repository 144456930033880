/* Base config settings */
$esp-base-font-size          : 14px;
$esp-base-font-family        : 'Helvetica Neue', Helvetica, Arial, sans-serif;
$esp-base-line-height        : 22px;

$esp-base-text-color         : #909090;
$esp-base-link-color         : #1C272F;
$esp-base-link-color-hover   : darken(#1C272F, 15);
$esp-base-link-color-visited : #1C272F;
$esp-base-btn-color-hover    : #ffffff;
$esp-base-background-color   : #ffffff;
$esp-base-body-color         : #ffffff;

$esp-brand-primary   : #FA2600;
$esp-brand-secondary : #1C272F;

/* Responsive named breakpoints */
$esp-mobile-max       : (719 / 16) * 1em;  /* 44.9375 */
$esp-mobile-horiz-min : (376 / 16) * 1em;  /* 23.5 */
$esp-tablet-small-min : (720 / 16) * 1em;  /* 45 */
$esp-tablet-large-min : (800 / 16) * 1em;  /* 50 */
$esp-tablet-max       : (1024 / 16) * 1em; /* 63.9375 */
$esp-desk-min         : (1024 / 16) * 1em; /* 64 */
$esp-desk-max         : (1200 / 16) * 1em; /* 75 */

$esp-breakpoints: (
  "palm"              "screen and (max-width: #{$esp-mobile-max})",
  "palm-horiz"        "screen and (min-width: #{$esp-mobile-horiz-min}) and (max-width: #{$esp-mobile-max})",
  "palm-horiz-and-up" "screen and (min-width: #{$esp-mobile-horiz-min})",
  "lap"               "screen and (min-width: #{$esp-tablet-small-min}) and (max-width: #{$esp-tablet-max})",
  "lap-and-up"        "screen and (min-width: #{$esp-tablet-small-min})",
  "portable"          "screen and (min-width: #{$esp-tablet-large-min}) and (max-width: #{$esp-tablet-max})",
  "desk"              "screen and (min-width: #{$esp-desk-min})",
  "desk-wide"         "screen and (min-width: #{$esp-desk-max})",
  "retina"            "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)",
);

$esp-widths-columns            : ( 1, 2, 3, 4, 5, 6, );
$esp-widths-columns-responsive : ( 1, 2, 3, 4, 5, 6, );

// Do not modify
$esp-base-spacing-unit:         $esp-base-line-height;
$esp-base-spacing-unit--tiny:   round($esp-base-spacing-unit / 4);
$esp-base-spacing-unit--small:  round($esp-base-spacing-unit / 2);
$esp-base-spacing-unit--large:  round($esp-base-spacing-unit * 2);
$esp-base-spacing-unit--huge:   round($esp-base-spacing-unit * 4);
