.grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
  .grid__item {
    flex: auto;

    &.is-aligned-top {
      align-self: flex-start;
    }

    &.is-aligned-bottom {
      align-self: flex-end;
    }

    &.is-aligned-center {
      align-self: center;
    }

    &.is-full-width {
      flex: none;
      width: 100%;
    }

    @include esp-media-query('palm') {
      flex: none;
      width: 100%;
    }
  }

.grid {
  &.has-gutters {
    margin-bottom: $esp-base-spacing-unit;
    margin-left: (-$esp-base-spacing-unit);
    margin-top: (-$esp-base-spacing-unit);

    > .grid__item {
      padding: $esp-base-spacing-unit 0 0 $esp-base-spacing-unit;
    }
  }

  &.has-padding {
    padding-top: $esp-base-spacing-unit;
    padding-bottom: $esp-base-spacing-unit;
  }

  &.has-small-padding {
    padding-top: $esp-base-spacing-unit--small;
    padding-bottom: $esp-base-spacing-unit--small;
  }

  &.has-tiny-gutters {
    margin-bottom: $esp-base-spacing-unit--tiny;
    margin-left: (-$esp-base-spacing-unit--tiny);
    margin-top: (-$esp-base-spacing-unit--tiny);

    > .grid__item {
      padding: $esp-base-spacing-unit--tiny 0 0 $esp-base-spacing-unit--tiny;
    }
  }

  &.has-small-gutters {
    margin-bottom: $esp-base-spacing-unit--small;
    margin-left: (-$esp-base-spacing-unit--small);
    margin-top: (-$esp-base-spacing-unit--small);

    > .grid__item {
      padding: $esp-base-spacing-unit--small 0 0 $esp-base-spacing-unit--small;
    }
  }

  &.has-large-gutters {
    margin-bottom: $esp-base-spacing-unit--large;
    margin-left: (-$esp-base-spacing-unit--large);
    margin-top: (-$esp-base-spacing-unit--large);

    > .grid__item {
      padding: $esp-base-spacing-unit--large 0 0 $esp-base-spacing-unit--large;
    }
  }

  &.has-huge-gutters {
    margin-bottom: $esp-base-spacing-unit--huge;
    margin-left: (-$esp-base-spacing-unit--huge);
    margin-top: (-$esp-base-spacing-unit--huge);

    > .grid__item {
      padding: $esp-base-spacing-unit--huge 0 0 $esp-base-spacing-unit--huge;
    }
  }

  &.has-flexed-items {
    > .grid__item {
      display: flex;
    }
  }

  &.is-aligned-top {
    align-items: flex-start;
  }

  &.is-aligned-bottom {
    align-items: flex-end;
  }

  &.is-aligned-center {
    align-items: center;
  }

  &.is-justified-center {
    justify-content: center;
  }

  &.is-justified-start {
    justify-content: flex-start;
  }

  &.is-justified-end {
    justify-content: flex-end;
  }

  &.is-reversed {
    flex-direction: row-reverse;
  }
}
